<template>
  <div>
    <div class="vd_dis">
      <div class="vg_mt_16 vg_mb_8">
        <img v-if="stateFlag === 2" :style="{ width: widlen + 'px', height: heilen + 'px' }" src="@/assets/image/disable.png" />
        <img v-if="stateFlag === 0" :style="{ width: widlen + 'px', height: heilen + 'px' }" src="@/assets/image/notpass.png" />
        <img v-if="stateFlag === 1" :style="{ width: widlen + 'px', height: heilen + 'px' }" src="@/assets/image/complate.png" />
        <img v-if="stateFlag === 3" :style="{ width: widlen + 'px', height: heilen + 'px' }" src="@/assets/image/pass.png" />
      </div>
      <div>
        <span v-if="stateFlag === 0" class="vd_color_i">未经历</span>
        <span v-if="stateFlag === 2" class="vd_color_r">不经历</span>
        <span v-if="stateFlag === 1" class="vd_color_g">已完成</span>
        <span v-if="stateFlag === 3" class="vd_color_c">正在经历</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nonPlush',
  props: {
    stateFlag: { type: Number, required: true },
    widlen: { type: Number, required: true },
    heilen: { type: Number, required: true }
  }
};
</script>

<style lang="scss" scoped>
.vd_dis {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_color_r {
  color: rgb(255, 0, 0);
}
.vd_color_g {
  color: #1ca800;
}
.vd_color_i {
  color: rgb(81, 81, 81);
}
.vd_color_c {
  color: rgb(85, 162, 245);
}
</style>
