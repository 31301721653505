<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="min" :model="searchForm" ref="searchForm">
          <el-row>
            <!-- <el-col :md="8">
              <el-form-item  label="委托单号:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.requ_no"
                  clearable
                  placeholder="请填写委托单号"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :md="8">
              <el-form-item label="样品编号:">
                <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写样品编号"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :md="8">
              <el-form-item label="样品名称:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.smpl_name"
                  clearable
                  placeholder="请填写样品名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row> -->
            <!-- <el-col :md="8">
              <el-form-item label="打样部门:">
                <el-select
                  filterable
                  v-model="searchForm.belo_dept_id"
                  placeholder="请选择打样部门"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in deptList"
                    :key="item.id"
                    :label="item.param1"
                    :value="item.param2"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :md="8">
              <el-form-item label="样品类型:">
                <el-select
                  filterable
                  v-model="searchForm.smpl_type"
                  placeholder="请选择样品类型"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in smplType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :md="16">
              <el-form-item label="创建时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getNpsmsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            class="vg_cursor"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
          >
            <!-- @sort-change="sortChange" -->
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-row v-if="!loadFlag">
                  <el-col :md="3">
                    <div class="vd_dis_flex">
                      <span>外观3D状态</span>
                      <nonPlush
                        :stateFlag="scope.row.appearStatusList[scope.row.appearStatusList.length - 1]"
                        :widlen="50"
                        :heilen="50"
                      ></nonPlush>
                    </div>
                  </el-col>
                  <el-col :md="3">
                    <div class="vd_dis_flex">
                      <span>结构3D状态</span>
                      <nonPlush
                        :stateFlag="scope.row.structStatusList[scope.row.structStatusList.length - 1]"
                        :widlen="50"
                        :heilen="50"
                      ></nonPlush>
                    </div>
                  </el-col>
                  <el-col :md="3">
                    <div class="vd_dis_flex">
                      <span>3D样状态</span>
                      <nonPlush
                        :stateFlag="scope.row.kindStatusList[scope.row.kindStatusList.length - 1]"
                        :widlen="50"
                        :heilen="50"
                      ></nonPlush>
                    </div>
                  </el-col>
                  <el-col :md="3">
                    <div class="vd_dis_flex">
                      <span>色块样状态</span>
                      <nonPlush
                        :stateFlag="scope.row.colrswatStatusList[scope.row.colrswatStatusList.length - 1]"
                        :widlen="50"
                        :heilen="50"
                      ></nonPlush>
                    </div>
                  </el-col>
                  <el-col :md="6" class="vd_bor_le" style="border: 1px solid #000">
                    <el-row><el-col :md="24" class="vd_dis_flex vd_bor_bot">样品模具Sample Mold</el-col></el-row>
                    <el-row class="vg_mt_8">
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>通知开模状态</span>
                          <nonPlush :stateFlag="scope.row.smpl_openmold_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>模具完成状态</span>
                          <nonPlush :stateFlag="scope.row.smpl_moldcomp_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>交样状态</span>
                          <nonPlush :stateFlag="scope.row.smpl_delismpl_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :md="6" class="vd_bor_le" style="border: 1px solid #000">
                    <el-row><el-col :md="24" class="vd_dis_flex vd_bor_bot">大货模具Production Mold</el-col></el-row>
                    <el-row class="vg_mt_8">
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>通知开模状态</span>
                          <nonPlush :stateFlag="scope.row.prod_openmold_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>模具完成状态</span>
                          <nonPlush :stateFlag="scope.row.prod_moldcomp_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                      <el-col :md="8">
                        <div class="vd_dis_flex">
                          <span>交样状态</span>
                          <nonPlush :stateFlag="scope.row.prod_delismpl_status" :widlen="30" :heilen="30"></nonPlush>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="样品系列名" prop="smpl_theme" />
            <el-table-column label="样品编号" prop="smpl_no" />
            <el-table-column label="样品英文名称" prop="smpl_name_en" />
            <el-table-column label="委托人" prop="npsm_stff_name">
              <template slot-scope="scope">
                <span v-if="scope.row.npsm_stff_name">
                  {{ scope.row.npsm_stff_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="样品类型" prop="smpl_type">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_type">
                  {{ scope.row.smpl_type | formatSmplType}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <el-table-column label="客户样品数量" prop="smpl_cust_num">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_cust_num">
                  {{ scope.row.smpl_cust_num }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="打样工厂" prop="cust_country">
              <template slot-scope="scope">
                <span v-if="scope.row.cust_country">
                  {{ scope.row.cust_country }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              sortable
              label="实际交样时间"
              prop="npsm_deli_time"
              :formatter="formDeliTime"
            /> -->
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{
                  helper.getStatusName(scope.row.status).name
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { npsmAPI } from '@api/modules/npsm';
import { userAPI } from '@api/modules/user';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import selectStatus from '@/views/component/selectStatus';
import publicTips from '@/views/component/publicTips';
import nonPlush from '@/views/component/nonPlush';

export default {
  name: 'NpsmList',
  components: {
    pubPagination,
    selectStatus,
    publicTips,
    selectSectionType,
    nonPlush
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        requ_no: null,
        smpl_no: null,
        smpl_name: null,
        smpl_type: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      totalPage: 0,
      btn: {},
      npsm_receive: [],
      loading: true,
      multiSelection: [],
      deptList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      currentPage: 1,
      stffType: [],
      loadFlag: true,
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 样品类型转换
    formatSmplType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/npsm_edit' || from.path === '/npsm_add') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      // this.getOptnByPermId();
      this.getNpsmsList();
      this.getStffType();
    },
    // 获取供应商信息
    getNpsmsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(npsmAPI.getNpsms, {
        requ_no: this.searchForm.requ_no,
        smpl_name: this.searchForm.smpl_name,
        smpl_type: this.searchForm.smpl_type,
        smpl_no: this.searchForm.smpl_no,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data.form.optn_cntt_list;
            let temp = {};
            temp.id = 0;
            temp.param1 = '全部';
            temp.param2 = '';
            this.deptList.unshift(temp);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        requ_no: null,
        smpl_no: null,
        smpl_name: null,
        smpl_type: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      this.loadFlag = true;
      // this.$refs.selectStatus.changeValue();
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getNpsmsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getNpsmsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/npsm_edit', { perm_id: permId, form_id: row.npsm_id });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/npsm_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getNpsmsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formDeliTime(row) {
      return this.helper.toTimeDay(row.npsm_deli_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '已生效的数据不可删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.npsm_id);
          });
          post(npsmAPI.deleteNpsmByIds, { npsm_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      console.log('val.prop', val.prop);
      if (val.order == 'ascending') {
        this.searchForm.npsm_sort = 'asc';
        if (val.prop === 'npsm_stff_name') {
          this.searchForm.npsm_field = 'CONVERT(npsm_stff_name using GBK)';
        } else {
          this.searchForm.npsm_field = val.prop;
        }
      } else if (val.order == 'descending') {
        this.searchForm.npsm_sort = 'desc';
        if (val.prop === 'npsm_stff_name') {
          this.searchForm.npsm_field = 'CONVERT(npsm_stff_name using GBK)';
        } else {
          this.searchForm.npsm_field = val.prop;
        }
      } else {
        this.searchForm.npsm_sort = '';
        this.searchForm.npsm_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();

      // 升序 asc
      // 降序 desc
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_bor_le {
  border-left: 1px solid #3d3d3d;
}
.vd_bor_bot {
  border-bottom: 1px solid #3d3d3d;
}
</style>
